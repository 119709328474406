module.exports = [{
      plugin: require('/Users/john/Sites/johnfryme-v3/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630},
    },{
      plugin: require('/Users/john/Sites/johnfryme-v3/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-125329892-1","head":false},
    },{
      plugin: require('/Users/john/Sites/johnfryme-v3/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
